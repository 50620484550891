<template>
	<v-card class="m-0 p-0">
		<v-col cols="12" lg="12" md="12" style="display: contents;">
			<template style="margin-top: 10px;">
				<!-- :single-row="false" -->
				<s-crud
					:filter="filter"
					:config="config"
					print
					addNoFunction
					no-full
					search-input
					title="Impresion QR Pallet"
					@rowSelected="rowSelected($event)"
					@print="printQR($event)"
				>
					<template v-slot:filter>
						<v-container>
							<v-row style="margin-left: 5px">
								<v-col cols="12" md="4" lg="2">
									<s-select-definition
										:def="1181"
										label="Destino"
										return-object
										@input="inputDestiny($event)"
									>
									</s-select-definition>
								</v-col>
								<v-col cols="12" md="4" lg="2">
									<s-date
										label="Fecha Inicio"
										v-model="filter.cDateInitial"
									></s-date>
								</v-col>
								<v-col cols="12" md="4" lg="2">
									<s-date
										label="Fecha Fin"
										v-model="filter.cDateFin"
									></s-date>
								</v-col>
								<v-col cols="12" md="6" lg="3">
									<s-select-definition
									 
									v-model="filter.PltState"
									label="Estado de Pallet"
									:def="1394"
									></s-select-definition>
								</v-col>
								<v-col cols="12" md="6" lg="3">
									<s-select-definition
									clearable
									:def="1279"
									label="Calidad Pallete"
									v-model="filter.TypeQualityPallet"
								/>
							</v-col>
							</v-row>
						</v-container>
					</template>

					<!-- <template v-slot:inCamera="{ row }">
						<v-chip
							x-small
							:color="row.inCamera == 1 ? 'warning' : row.inCamera == 2 && row.inShipment >= 1 ? 'success' : row.inCamera == 2 ? 'info' : 'error'"
						>
							{{row.inCamera == 1 ? 'En Camara' : row.inCamera == 2 && row.inShipment >= 1 ? 'Embarque' : row.inCamera == 2 ? 'Recogido': 'Pendiente'}}
							
						</v-chip>
					</template> -->

					<template v-slot:TypeQualityPalletName={row}>
						<v-chip
							x-small
							dark
							:color="row.TypeQualityPalletName =='Process' ? 'error' : 'light-blue darken-2'">

							{{  row.TypeQualityPalletName}}

						</v-chip>
					</template>

					<template v-slot:TypeBalance="{ row }">
						<v-chip
							x-small
							:color="row.TypeBalance == false ? 'error' : 'info'"
						>
							{{row.TypeBalance == false ? 'SA': 'PA'}}
							
						</v-chip>
					</template>

					
				</s-crud>
			</template>
		</v-col>
		<v-row justify="center" cols="12" style="display:none ">
			<v-col cols="12" v-for="item in selected">
				<qr-code
					:text="item.PltID +', ' +item.UwoCustomerName +', ' +item.OrdID +', ' +item.FagName +', ' +  item.TypeCaliberName + ', ' + item.TypeCropName + ', ' + item.VrtName + ', '+ item.TypeQualityPalletName + ', ' + item.UwoMaterialListNumberBoxes +', PN: ' +item.PltWeightNet +', LP: ' +item.RcfID +', '+ item.PltDate"
					error-level="H"
					hidden
					:size="300"
				>
				</qr-code
				><br />
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
	import _sPackingService from "../../../services/FreshProduction/PackingService";
	import qrCode from "vue-qr-generator";
	export default {
		props: {},
		components: { qrCode },
		data: () => ({
			filter: {
				cDateInitial: "",
				cDateFin: "",
				ordAreProduction: "",
				cBalance: 1,
				PltState: 1
			},
			config: {
				service: _sPackingService,
				model: {
					PltID: "ID",
					PltDate: "datetime",
					inCamera: "",
					TypeBalance: "",
					TypeQualityPalletName:"",
				},
				headers: [
					/* { text: "Estado", value: "inCamera", width: 100 }, */
					{ text: "Tipo", value: "TypeBalance", width: 50 },
					{ text: "Nro. Pallet", value: "PltID", width: 100 },
					{ text: "correlativo", value: "PltNumberCorrelative", width: 50 },
					{
						text: "Producto Terminado",
						value: "UwoMaterialListID",
						width: 140
					},
					{ text: "Pedido", value: "OrdID", width: 100 },
					{ text: "Fundo/Usuario", value: "FagName", width: 340 },
					{ text: "Cliente.", value: "UwoCustomerName", width: 340 },
					{ text: "Cultivo", value: "TypeCultiveName", width: 140 },
					{ text: "Tipo Cultivo", value: "TypeCropName", width: 140 },
					{
						text: "Cantidad",
						value: "UwoMaterialListNumberBoxes",
						width: 140
					},
					{ text: "Peso", value: "PltWeightNet", width: 140 },
					{ text: "Fecha", value: "PltDate", width: 140 },
					{ text: "calidad", value:"TypeQualityPalletName", width: 140 },
				]
			},
			selected: [],
			itemsDetail: [],
			getDate: null,
			typeBalacenDisabled: false,
			typeBalacenDisabledText: "",
			keyPrint: false,
		}),

		methods: {
			inputDestiny(val) {
				if (val !== undefined) {
					this.filter.ordAreProduction = val.DedAbbreviation;
				}
			},

			rowSelected(val) {

				this.keyPrint = false;
				console.log('lo que me trae en el rowselect', val)

				if (val.length > 0) {
					if (val !== undefined) {
						this.selected = val;
						this.typeBalacenDisabled = this.selected[0].TypeBalance

						this.getDate = this.$fun.getDate() + " " + this.$fun.getHour();
						
						_sPackingService
							.GetDetail({ PltID: this.selected[0].PltID, xPrint: 1 }, this.$fun.getUserID())
							.then(r => {
								this.itemsDetail = r.data;
								console.log("this.itemsDetail", this.itemsDetail);

								// this.itemsDetail.forEach(element => {
								// 	if(element.PltID != this.selected[0].PltID){
								// 		keyPrin = true;
								// 	}
								// });

							});


						if(!this.typeBalacenDisabled){
							this.typeBalacenDisabledText = "SALDO"
						}else{
							this.typeBalacenDisabledText = ""
						}

						console.log("rowSelected", this.selected, this.typeBalacenDisabled);
					}
				}
				
			},

			isSum(){
				var sum = 0
				this.itemsDetail.forEach(element => {
					sum += parseInt(element.PldQuantityBoxes)
				});

				return sum;
			},

			printQR() {

				var keyPrin = false; 
				this.itemsDetail.forEach(element => {
					if(element.PltID != this.selected[0].PltID){
						keyPrin = true;
					}
				});

				if (keyPrin) {
					this.$fun.alert("La información no pertenece al pallet seleccionado","warning")
					return;
				}

				let process = "";
				if (this.selected[0].TypeQualityPallet == 7) {
					process = "<b style='font-size: 40px'>PROCESS</b>";
				}
				
				console.log('que viene para imprimir', this.selected[0].TypeQualityPallet)

				var imgAll = document.querySelector("img");

				var Pagelink = "about:blank";
				var pwa = window.open(Pagelink, "_new");
				pwa.document.open();
				pwa.document.write(
					"<html><head><scri" +
						"pt>function step1(){\n" +
						"setTimeout('step2()', 10);}\n" +
						"function step2(){window.print();window.close()}\n" +
						"</scri" +
						"pt><style media='print'>  @media print  { @page { margin-left: 1cm; {} </style></head><body onload='step1()'> \n" +
						"<div class='col col-12 justify-center'>"
				);

				/* imgAll.forEach(element => { col col-4*/
				pwa.document.write(
					"<div class='' style='margin: 20px 20px 20px 190px;'><img alt='Scan me!' style='display: block;'  src='" +
						imgAll.currentSrc +
						"' /></div>"
				);
				/* }); */

				pwa.document.write("<br><br><div ><table border='0'>");
				pwa.document.write(
					"<tr>" +
						"<td colspan='3' style='width: 700px;text-align: end; font-size: 20px; font-family: Courier New;'><b>Pallet Ticket</b></td>" +
						"</tr>"
				);
				pwa.document.write("</table></div>");

				pwa.document.write("<table border='0'>");
				pwa.document.write(
					"<tr>" +
							"<td style='font-family: Times New Roman;'>Client:</td>" +
							"<td  style='font-family: Times New Roman;'><b>" +this.selected[0].UwoCustomerName +"</b></td>" +
							"<td style='width: 150px; font-family: Times New Roman;'>Production Date:</td>" +
							"<td  style='font-family: Times New Roman; font-size: 22px;'>" +this.selected[0].PltDate +"</td>" +
						"</tr>" +
						"<tr style='width: 50px'>" +
							"<td style='width: 150px; font-family: Times New Roman;'>N° Correlative:</td>" +
							"<td style='font-size: 30px; font-family: Times New Roman;'><b>" +this.selected[0].PltNumberCorrelative +"</b></td>" +
							"<td style='font-family: Times New Roman;'>Crop:</td>" +
							"<td style='font-family: Times New Roman; font-size: 22px;'>" +this.selected[0].TypeCultiveName +"</td>" +
						"</tr>" +
						"<tr>" +
							"<td style='font-family: Times New Roman;'>Pallet Code:</td>" +
							"<td style='font-size: 30px; font-family: Times New Roman;'><b>" +this.selected[0].PltID +"</b></td>" +
							"<td style='font-family: Times New Roman;'>Type of Crop:</td>" +
							"<td style='font-family: Times New Roman; font-size: 22px;'>" +this.selected[0].TypeCropName +"</td>" +
						"<tr>" +
						"<tr>" +
							"<td style='font-family: Times New Roman;'>Product:</td>" +
							"<td colspan='3' style='width: 580px; font-family: Times New Roman; font-size: 25px;'>" +this.selected[0].UwoMaterialListName +"</td>" +
						"<tr>"
				);
				pwa.document.write("</table>");

				pwa.document.write("<table border='1' style='border-block-color: initial;border-style: hidden;'>");
				pwa.document.write(
					"<tr>" +
						"<td style='width: 170px; font-family: Times New Roman; font-size: 22px;'>Variety</td>" +
						"<td style='width: 170px; font-family: Times New Roman; font-size: 22px;'>Size</td>" +
						"<td style='width: 170px; font-family: Times New Roman; font-size: 22px;'>FPB</td>" +
						"<td style='width: 170px; font-family: Times New Roman; font-size: 22px;'>Boxes Quantity</td>" +
					"</tr>"
				);

				this.itemsDetail.forEach(element => {
					pwa.document.write(
						"<tr>" +
							"<td style='font-family: Times New Roman; font-size: 22px;'>"+element.VrtName+"</td>" +
							"<td style='font-family: Times New Roman; font-size: 22px;'>"+element.TypeCaliberName+"</td>" +
							"<td style='font-family: Times New Roman; font-size: 22px;'>"+element.PldFruitxBoxe+"</td>" +
							"<td style='text-align: end; font-family: Times New Roman; font-size: 22px;'>"+element.PldQuantityBoxes+"</td>" +
						"</tr>"
					);
				});
				

				pwa.document.write(
					"<tr>" +
						"<td colspan='3' style='text-align: end;'>Total</td>" +
						"<td style='text-align: end;'>"+this.isSum()+"</td>" +
					"<tr>"
				);
				
				pwa.document.write("</table>");
				pwa.document.write("<b style='font-size: 30px'>" +this.typeBalacenDisabledText+"</b>");
				pwa.document.write(process)
				pwa.document.write("</div>");
				pwa.document.write("<div style='font-size: 40px; width:100%;text-align:center;'><b >" + this.selected[0].TypeZonLocationName+"</b></div>");
				pwa.document.write("</body></html>");
				pwa.document.close();
			}
		}
	};
</script>

<style></style>
